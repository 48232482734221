import { FC } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { UserUsageAndLimitRo } from "src/app/services/generatedApi";

export const CreatorSelect: FC<{
  creator: string;
  usersUsageAndLimit: UserUsageAndLimitRo[];
  onChange: (event: SelectChangeEvent) => void;
}> = ({ creator, usersUsageAndLimit, onChange }) => (
  <FormControl fullWidth size="small" sx={{ width: "100%", maxWidth: "352px" }}>
    <InputLabel id="creatorLabelId">Creator</InputLabel>
    <Select
      labelId="creatorLabelId"
      id="creator"
      value={creator}
      label="Creator"
      onChange={onChange}
    >
      <MenuItem value="all">All</MenuItem>
      {usersUsageAndLimit.map(({ creator }) => (
        <MenuItem key={creator} value={creator}>
          {creator}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
