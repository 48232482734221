import { FC, Dispatch, SetStateAction } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

export const SearchField: FC<{
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}> = ({ search, setSearch }) => (
  <TextField
    sx={{ width: "100%", maxWidth: "352px" }}
    size="small"
    placeholder="Search by name"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    value={search}
    onChange={(e) => setSearch(e.target.value)}
  />
);
