import React, { useState, useMemo } from "react";
import {
  Button,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import {
  CategoryRo,
  useCategoryControllerDeleteCategoryMutation,
  useCategoryControllerUpdateCategoryMutation,
} from "src/app/services/generatedApi";
import CategorySettings from "./CategorySettings";
import Loading from "src/components/atoms/Loading";
import { NameEditor } from "src/components/molecules/category/NameEditor";
import { useAppSelector } from "src/app/hooks";
import { DeleteConfirmationDialog } from "./DeleteConfirmationDialog";
import { useLocalState } from "./hooks";

interface CategoryCardProps {
  category: CategoryRo;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category }) => {
  const { id, name: initialName, setting, isSystemCategory } = category;
  const {
    localSetting,
    localName,
    setLocalName,
    isEditingName,
    setIsEditingName,
    handleSettingChange,
  } = useLocalState(setting, initialName);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));
  const { auth } = useAppSelector((state) => state);
  const [deleteCategory, { isLoading: deleteLoading }] =
    useCategoryControllerDeleteCategoryMutation();
  const [editCategory, { isLoading: editLoading }] =
    useCategoryControllerUpdateCategoryMutation();

  const isEqual = useMemo(
    () => shallowEqual(setting, localSetting) && initialName === localName,
    [localSetting, setting, initialName, localName],
  );

  const handleDeleteConfirm = () => {
    deleteCategory({ idDto: { id } })
      .unwrap()
      .then(() => {
        toast.success("Angle thresholds successfully deleted");
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        toast.error("Failed to delete angle thresholds");
        console.error("Delete error:", error);
      });
  };

  const handleEdit = () => {
    if (isEqual || !localSetting) return;
    editCategory({
      id,
      updateCategoryDto: { name: localName, setting: localSetting },
    })
      .unwrap()
      .then(() => toast.success("Category successfully modified"))
      .catch((error) => {
        toast.error("Failed to modify category");
        console.error("Edit error:", error);
      });
  };

  return (
    <Paper
      component={Stack}
      direction="column"
      elevation={isMd ? undefined : 0}
      sx={{
        width: "100%",
        px: { xs: 3, sm: 2, md: 1 },
        py: 2,
        borderRadius: { xs: 0, md: 1 },
        position: "relative",
      }}
    >
      {(deleteLoading || editLoading) && <Loading />}
      <NameEditor
        localName={localName}
        setLocalName={setLocalName}
        isEditingName={isEditingName}
        setIsEditingName={setIsEditingName}
        initialName={initialName}
        isSystemCategory={isSystemCategory}
      />
      {isSystemCategory && (
        <Typography
          align="center"
          variant="h6"
          fontWeight="bold"
          component="div"
          color="primary"
        >
          (SYSTEM ANGLE THRESHOLD)
        </Typography>
      )}
      {localSetting && (
        <CategorySettings value={localSetting} onChange={handleSettingChange} />
      )}
      {(!isSystemCategory || auth?.isAdmin) && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ pt: 3 }}
        >
          <Button
            sx={{ width: "100%", maxWidth: "130px" }}
            variant="contained"
            color="info"
            onClick={handleEdit}
            disabled={isEqual}
          >
            Update
          </Button>
          <Button
            sx={{ width: "100%", maxWidth: "130px" }}
            variant="outlined"
            color="error"
            onClick={() => setOpenDeleteDialog(true)}
          >
            Delete
          </Button>
        </Stack>
      )}

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteConfirm}
      />
    </Paper>
  );
};

export default CategoryCard;
