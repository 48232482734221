import { SettingDto } from "src/app/services/generatedApi";

interface MarksFuncType {
  spaceBetween: number;
  start: number;
  end: number;
}

export const generateMarks = ({ spaceBetween, start, end }: MarksFuncType) =>
  Array.from(
    { length: Math.floor((end - start) / spaceBetween) + 1 },
    (_, i) => ({
      value: start + i * spaceBetween,
      label: `${start + i * spaceBetween}°`,
    }),
  );

interface AngleToPercentageProps {
  number: number;
  itemName: keyof SettingDto;
  minMax: (itemName: keyof SettingDto) => { min: number; max: number };
}

export const angleToPercentage = ({
  number,
  itemName,
  minMax,
}: AngleToPercentageProps) => {
  const { min, max } = minMax(itemName);
  return ((number - min) / (max - min)) * 100;
};
