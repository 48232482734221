import { FC, useMemo, useState } from "react";
import { Navigate, useParams } from "react-router";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import Template from "src/components/templates/Template";
import { useTasksControllerGetTaskByIdForUserQuery } from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";
import { settingKeys } from "src/app/logic/pose/poseConstants";
import { SettingsNamesEnum } from "src/components/molecules/category/types";
import { shallowEqual } from "react-redux";
import { TaskView } from "src/components/organisms/compare/TaskView";
import { CompareBarChart } from "src/components/organisms/compare/charts/CompareBarChart";
import { calculateMinChartData } from "src/components/organisms/compare/helpers/chartData";
import { RadarBarChart } from "src/components/organisms/compare/charts/RadarBarChart";
import { ChangeChartModeSwitch } from "src/components/molecules/compare/ChangeChartModeSwitch";

const Compare: FC = () => {
  const [isBarChart, setIsBarChart] = useState(true);
  const [chartData, setChartData] = useState(
    settingKeys.map((key) => ({
      key: SettingsNamesEnum[key],
      l: { score: 0, visibleCount: 0 },
      r: { score: 0, visibleCount: 0 },
    })),
  );

  const minChartData = useMemo(
    () => calculateMinChartData(chartData),
    [chartData],
  );

  const { id1, id2 } = useParams();
  const { data: task1, isLoading: isLoading1 } =
    useTasksControllerGetTaskByIdForUserQuery({ id: id1 as string });
  const { data: task2, isLoading: isLoading2 } =
    useTasksControllerGetTaskByIdForUserQuery({ id: id2 as string });

  if (!id1 || !id2) {
    return <Navigate to="/results" />;
  }

  if (task1 && task2) {
    if (task1.state !== "READY" || task2.state !== "READY") {
      return <Navigate to="/results" />;
    }
  }

  const updateChartData = (
    data: {
      score: number;
      visibleCount: number;
    }[],
    side: "l" | "r",
  ) => {
    const haveChange = chartData.some(
      (cd, idx) => !shallowEqual(cd[side], data[idx]),
    );
    if (haveChange) {
      setChartData((prev) =>
        prev.map((item, index) => ({
          ...item,
          [side]: data[index],
        })),
      );
    }
  };

  return (
    <Template>
      {(isLoading1 || isLoading2) && <Loading />}
      <Box sx={{ position: "relative" }}>
        <Grid
          container
          columnSpacing={{ xs: 0, lg: 2 }}
          rowSpacing={{ xs: 4, lg: 0 }}
        >
          <Grid item xs={12} lg={6} sx={{ p: 0 }}>
            {task1 && (
              <TaskView
                task={task1}
                isRight
                onChangePartsState={(data) => updateChartData(data, "l")}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6} sx={{ p: 0 }}>
            {task2 && (
              <TaskView
                task={task2}
                onChangePartsState={(data) => updateChartData(data, "r")}
              />
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            position: "absolute",
            top: 410,
            right: 20,
            height: 50,
            display: { xs: "none", lg: "block" },
            zIndex: 10,
          }}
        >
          <ChangeChartModeSwitch
            checked={isBarChart}
            onChange={() => setIsBarChart((prev) => !prev)}
          />
        </Box>
        <Paper
          sx={{
            position: "absolute",
            top: 400,
            left: 0,
            height: 480,
            width: "100%",
            display: { xs: "none", lg: "block" },
            zIndex: -1,
            pt: 2,
          }}
        >
          <Typography align="center" variant="h6">
            Comparing Postural Risk Scores
          </Typography>
        </Paper>
        <Stack
          sx={{
            position: { xs: "static", lg: "absolute" },
            top: 470,
            left: 0,
            height: 380,
            width: "100%",
            pointerEvents: "none",
            ...(isBarChart && { transform: "rotate(90deg)" }),
          }}
          alignItems="center"
          justifyContent="center"
        >
          {task1 &&
            task2 &&
            (isBarChart ? (
              <CompareBarChart chartData={minChartData} />
            ) : (
              <RadarBarChart chartData={minChartData} />
            ))}
        </Stack>
      </Box>
    </Template>
  );
};

export default Compare;
