import { mean } from "lodash";
import { settingKeys } from "src/app/logic/pose/poseConstants";
import {
  ResultRo,
  SettingDto,
  TaskRo,
  ThresholdDto,
} from "src/app/services/generatedApi";
import { SettingsNamesEnum } from "src/components/molecules/category/types";

type chartUtilsType = {
  task: TaskRo;
  selectedPart: keyof SettingDto;
};

const justReturnAmounts = ({ task, selectedPart }: chartUtilsType) => {
  const arr1 = task.result?.map((item) => item[selectedPart]) || [];
  const arr2 = arr1.filter(
    (item) => item !== undefined && item !== null && !isNaN(item) && item,
  ) as number[];
  return arr2;
};

export const calcAverage = ({ task, selectedPart }: chartUtilsType) =>
  mean(justReturnAmounts({ task, selectedPart }));

type resultStateType = {
  thresholds: ThresholdDto;
  number: number;
};

export const resultState = ({ thresholds, number }: resultStateType) => {
  const { threshold1, threshold2, threshold3, threshold4 } = thresholds;
  let result: "hazard" | "caution" | "safe";
  if (
    (number >= threshold1 && number < threshold2) ||
    (number >= threshold3 && number < threshold4)
  ) {
    result = "caution";
  } else if (number >= threshold2 && number < threshold3) {
    result = "safe";
  } else {
    result = "hazard";
  }
  return result;
};

export const resultPercentage = ({ task, selectedPart }: chartUtilsType) => {
  let safe = 0,
    hazard = 0,
    caution = 0;

  const localArr = justReturnAmounts({ task, selectedPart });

  const arrayLength = localArr.length;
  // eslint-disable-next-line array-callback-return
  localArr.map((item) => {
    const state = resultState({
      thresholds: task.setting[selectedPart],
      number: item,
    });
    switch (state) {
      case "caution":
        caution++;
        break;
      case "safe":
        safe++;
        break;
      default:
        hazard++;
        break;
    }
  });

  return {
    name: SettingsNamesEnum[selectedPart],
    safe: Math.round((safe / arrayLength) * 1000) / 10,
    caution: Math.round((caution / arrayLength) * 1000) / 10,
    hazard: Math.round((hazard / arrayLength) * 1000) / 10,
  };
};

export const resultPercentageArray = (task: TaskRo) =>
  settingKeys.map((item) =>
    resultPercentage({ task, selectedPart: item as keyof SettingDto }),
  );

type binarySearchType = {
  arr: any[];
  x: any;
};

export const binarySearch = ({ arr, x }: binarySearchType) => {
  let start = 0,
    end = arr.length - 1;

  // Iterate while start not meets end
  while (start <= end) {
    // Find the mid index
    let mid = Math.round((start + end) / 2);

    // If element is present at mid, return True
    if (arr[mid] === x) return mid;
    // Else look in left or right half accordingly
    else if (arr[mid] < x) start = mid + 1;
    else end = mid - 1;
  }

  return start;
};

type convertNeckDataType = {
  result: ResultRo;
  selectedPart: keyof SettingDto;
};

export const convertNeckData = ({
  result,
  selectedPart,
}: convertNeckDataType) => Math.round(result[selectedPart] as any);

export const twoDecimalPlaces = (number: number) =>
  number === 0 ? 0 : Math.round(number * 1e2) / 1e2;

type dataPreparerType = {
  results: ResultRo[];
  selectedPart: keyof SettingDto;
};

export const dataPreparer = ({ results, selectedPart }: dataPreparerType) =>
  results.map((result) => ({
    angle: convertNeckData({
      result,
      selectedPart: selectedPart as keyof SettingDto,
    }),
    seconds: twoDecimalPlaces(0), //TODO:
  }));

export const roundAngles = (results: ResultRo[]) => {
  return results.map((frameAngles) => {
    const newFrameAngle: ResultRo = {};
    settingKeys.forEach((settingKey) => {
      const value = frameAngles[settingKey];
      newFrameAngle[settingKey] = value && twoDecimalPlaces(value);
    });
    return newFrameAngle;
  });
};
