import React, { FC, useState, MouseEvent, ChangeEvent } from "react";
import {
  Table,
  TableContainer,
  TablePagination,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Template from "src/components/templates/Template";
import {
  MinimalCategoryRo,
  MinimalTaskRo,
  TagRo,
} from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";
import { TableToolbar } from "src/components/organisms/tasks/TableToolbar";
import ToolbarFilters from "src/components/molecules/tasks/toolbar/ToolbarFilters";
import ToolbarActions from "src/components/organisms/tasks/ToolbarActions";
import TasksTableBody from "src/components/organisms/tasks/TableBody";
import { useNavigate } from "react-router";
import TasksTableHead, {
  HeadCell,
} from "src/components/organisms/tasks/TableHead";
import { stateType, tableOrderType } from "./types";

interface BaseTasksProps {
  title: string;
  headCells: HeadCell[];
  fetchTasks: (params: any) => {
    data?: { tasks?: MinimalTaskRo[]; meta?: { total: number } };
    isLoading: boolean;
    isFetching: boolean;
  };
  isAllTasks?: boolean;
  onRefresh?: (taskId: string) => void;
}

const BaseTasksComponent: FC<BaseTasksProps> = ({
  title,
  headCells,
  fetchTasks,
  isAllTasks,
  onRefresh,
}) => {
  const [order, setOrder] = useState<tableOrderType>("desc");
  const [orderBy, setOrderBy] = useState<keyof MinimalTaskRo>("createdAt");
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState<string>("");
  const [tag, setTag] = useState<TagRo>({ id: "all", name: "All" });
  const [category, setCategory] = useState<MinimalCategoryRo>({
    name: "All",
    id: "all",
  });
  const [creator, setCreator] = useState<string>("all");

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  const { data, isLoading, isFetching } = fetchTasks({
    page: page + 1,
    limit: rowsPerPage,
    search,
    category: category.id,
    tag: tag.id,
    ...(isAllTasks && { creator }),
    orderBy,
    order,
  });

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof MinimalTaskRo,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = ({
    target: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      const newSelected = (data?.tasks || []).map(
        (task: MinimalTaskRo) => task.id,
      );
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  const navigate = useNavigate();

  const rowClickHandler = (task: MinimalTaskRo) => {
    if (task.state !== stateType.READY) return;
    navigate("/result/" + task.id);
  };

  return (
    <Template>
      {isLoading && <Loading />}
      <Paper
        elevation={isMd ? undefined : 0}
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: { xs: 0, md: 1 },
        }}
      >
        <TableToolbar
          selectedRows={selected}
          isFetching={isFetching}
          title={title}
          actions={
            selected.length > 0 ? (
              <ToolbarActions
                selectedRows={selected}
                setSelectedRows={setSelected}
              />
            ) : (
              <ToolbarFilters
                search={search}
                category={category}
                setSearch={setSearch}
                setCategory={setCategory}
                tag={tag}
                setTag={setTag}
                creator={creator}
                setCreator={setCreator}
                isAllTasks={isAllTasks}
              />
            )
          }
        />
        <TableContainer>
          <Table>
            <TasksTableHead
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={(data?.tasks || []).length}
            />
            <TasksTableBody
              rows={data?.tasks || []}
              selected={selected}
              setSelected={setSelected}
              order={order}
              orderBy={orderBy}
              onRowClick={rowClickHandler}
              isAllTasks={isAllTasks}
              onRefresh={onRefresh}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={data?.meta?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Template>
  );
};

export default BaseTasksComponent;
