import { FC, useMemo, useState, MouseEvent, ChangeEvent } from "react";
import {
  Table,
  TableContainer,
  TablePagination,
  Paper,
  useMediaQuery,
  useTheme,
  Button,
  Dialog,
} from "@mui/material";
import Template from "src/components/templates/Template";
import Loading from "src/components/atoms/Loading";
import {
  UserRo,
  useUsersControllerSearchUsersQuery,
  useUsersControllerGetAllUsersUsageQuery,
} from "src/app/services/generatedApi";
import { addUsersUsage, usersFilter } from "src/utils/usersUtils";
import UsersTableToolbar from "src/components/organisms/users/table/toolbar/UsersTableToolbar";
import { Add as AddIcon } from "@mui/icons-material";
import AddUser from "src/components/organisms/users/addUser/AddUser";
import UsersTableBody from "src/components/organisms/users/table/UsersTableBody";
import UsersTableHead from "src/components/organisms/users/table/UsersTableHead";
import { HeadIdType } from "src/components/organisms/users/table/struct";
import { tableOrderType } from "src/components/organisms/tasks/types";

const EnhancedTable: FC = () => {
  const { data: allUsers, isLoading: getUserLoading } =
    useUsersControllerSearchUsersQuery({});

  const { data: usersUsage, isLoading: getUserDurationLoading } =
    useUsersControllerGetAllUsersUsageQuery();

  const [order, setOrder] = useState<tableOrderType>("asc");
  const [orderBy, setOrderBy] = useState<HeadIdType>("username");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState<string>("");
  const [role, setRole] = useState("All");
  const [showModal, setShowModal] = useState(false);

  const openModalHandler = () => setShowModal(true);
  const closeModalHandler = () => setShowModal(false);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  const userWithUsage = useMemo(
    () =>
      addUsersUsage({
        users: allUsers?.users,
        usersUsageAndLimit: usersUsage?.usersUsageAndLimit,
      }),
    [allUsers?.users, usersUsage?.usersUsageAndLimit],
  );
  const rows = useMemo(() => {
    return usersFilter({
      originalArray: userWithUsage,
      selectedRole: role,
      inputValue: search,
      searchItem: "username" as keyof UserRo,
    });
  }, [role, search, userWithUsage]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: HeadIdType,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) =>
    setPage(newPage);

  const handleChangeRowsPerPage = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
  };

  return (
    <>
      {(getUserLoading || getUserDurationLoading) && <Loading />}
      <Template>
        <Button
          size="large"
          variant="contained"
          color="primary"
          sx={{
            ml: { xs: 2, md: 0 },
            mb: { xs: 2, md: 4 },
            mt: { xs: 2, md: 0 },
            width: "200px",
          }}
          startIcon={<AddIcon fontSize="large" />}
          onClick={openModalHandler}
        >
          Add new user
        </Button>
        <Paper
          elevation={isMd ? undefined : 0}
          sx={{
            width: "100%",
            overflow: "hidden",
            borderRadius: { xs: 0, md: 1 },
          }}
        >
          <UsersTableToolbar
            role={role}
            setRole={setRole}
            search={search}
            setSearch={setSearch}
          />
          <TableContainer>
            <Table>
              <UsersTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <UsersTableBody
                rows={rows}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Template>
      <Dialog
        fullWidth
        maxWidth="md"
        open={showModal}
        onClose={closeModalHandler}
      >
        <AddUser closeDialogHandler={closeModalHandler} />
      </Dialog>
    </>
  );
};

export default EnhancedTable;
