import { FC, ReactNode } from "react";
import { alpha } from "@mui/material/styles";
import {
  useMediaQuery,
  Toolbar,
  Typography,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/system";

type TableToolbarPropsType = {
  selectedRows: string[];
  isFetching: boolean;
  title: string;
  actions: ReactNode;
};

export const TableToolbar: FC<TableToolbarPropsType> = ({
  selectedRows,
  isFetching,
  title,
  actions,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Toolbar
      sx={{
        pt: 3,
        pb: 3,
        ...(selectedRows.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        width="100%"
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Box sx={{ width: 20, height: 20 }}>
            {isFetching && <CircularProgress size={20} />}
          </Box>
          {selectedRows.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selectedRows.length} selected
            </Typography>
          ) : (
            isMd && (
              <Typography
                variant="h6"
                id="tableTitle"
                component="div"
                width="100%"
                sx={{ whiteSpace: "nowrap" }}
              >
                {title}
              </Typography>
            )
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
        >
          {actions}
        </Stack>
      </Stack>
    </Toolbar>
  );
};
