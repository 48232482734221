import { FC } from "react";
import { Navigate, useParams } from "react-router";
import { Stack } from "@mui/material";
import { toast } from "react-toastify";
import Template from "src/components/templates/Template";
import { useTasksControllerGetTaskByIdForUserQuery } from "src/app/services/generatedApi";
import { useAppSelector } from "src/app/hooks";
import Loading from "src/components/atoms/Loading";
import { Result } from "src/components/organisms/result/Result";
import { DeleteTaskButton } from "src/components/molecules/task/DeleteTaskButton";

const Task: FC = () => {
  let { id } = useParams() as { id: string };
  const isAdmin = useAppSelector((state) => state.auth?.isAdmin);

  const {
    data: task,
    isLoading,
    isError,
  } = useTasksControllerGetTaskByIdForUserQuery({ id });

  if (isError) {
    toast.error("There is a problem, please contact support.");
    return <Navigate to="/results" />;
  }

  return (
    <Template>
      {isLoading && <Loading />}
      {task && (
        <Stack spacing={2}>
          <Result task={task} isCompare={false} />
          {isAdmin && <DeleteTaskButton taskId={id} />}
        </Stack>
      )}
    </Template>
  );
};

export default Task;
