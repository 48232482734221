import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTasksControllerDeleteTasksMutation } from "src/app/services/generatedApi";

interface DeleteTaskButtonProps {
  taskId: string;
}

export const DeleteTaskButton: React.FC<DeleteTaskButtonProps> = ({
  taskId,
}) => {
  const [deleteTasks, { isLoading: isDeleting }] =
    useTasksControllerDeleteTasksMutation();
  const navigate = useNavigate();

  const deleteTask = async () => {
    try {
      await deleteTasks({
        deleteTaskDto: {
          ids: [taskId],
          permanent: true,
        },
      });
      toast.success("Task permanently deleted");
      navigate("/results");
    } catch (error) {
      toast.error("Failed to delete task");
    }
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleConfirmDelete = async () => {
    await deleteTask();
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        onClick={() => setOpenConfirmDialog(true)}
        sx={{ alignSelf: "flex-end" }}
      >
        Permanently Delete
      </Button>

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Permanent Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to permanently delete this task? This action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            autoFocus
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting..." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
