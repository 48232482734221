import { FC, useEffect, useState } from "react";
import {
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Button,
} from "@mui/material";
import { CategoriesRo, TagsRo } from "src/app/services/generatedApi";
import { nameIdType } from "src/components/atoms/AutocompleteWithAdd";
import TaskInfoStep from "src/components/organisms/addVideo/TaskInfoStep";
import { UploadStep } from "src/components/organisms/addVideo/UploadStep";

const MultipleVideoUpload: FC<{
  allCategories?: CategoriesRo;
  allTags?: TagsRo;
}> = ({ allCategories, allTags }) => {
  const [taskName, setTaskName] = useState("");
  const [category, setCategory] = useState("");
  const [tag, setTag] = useState<string | nameIdType | null>(null);
  const [description, setDescription] = useState("");
  const [blurFace, setBlurFace] = useState(false);
  const [blurBack, setBlurBack] = useState(false);
  const [multiTarget, setMultiTarget] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Task Info",
      description:
        "Enter file details, select category, tags, and set blurring options.",
    },
    { label: "Upload", description: "Upload your videos." },
  ];

  useEffect(() => {
    if (allCategories) {
      const defaultCategory = allCategories.categories.find(
        (cat) => cat.name === "Default" && cat.isSystemCategory,
      );
      if (defaultCategory) {
        setCategory(defaultCategory.id);
      }
    }
  }, [allCategories]);

  const handleNext = () => {
    if (taskName) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleUploadCompleted = () => {
    setActiveStep(steps.length);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel>{step.label}</StepLabel>
          <StepContent>
            <Typography mb={2}>{step.description}</Typography>
            {index === 0 && (
              <TaskInfoStep
                taskName={taskName}
                setTaskName={setTaskName}
                category={category}
                setCategory={setCategory}
                tag={tag}
                setTag={setTag}
                description={description}
                setDescription={setDescription}
                blurFace={blurFace}
                setBlurFace={setBlurFace}
                blurBack={blurBack}
                setBlurBack={setBlurBack}
                multiTarget={multiTarget}
                setMultiTarget={setMultiTarget}
                allCategories={allCategories}
                allTags={allTags}
              />
            )}
            {index === 1 && (
              <UploadStep
                initialValues={{
                  taskName,
                  category,
                  tag,
                  description,
                  blurFace,
                  blurBack,
                  multiTarget,
                }}
                onCompleted={handleUploadCompleted}
                handleBack={handleBack}
              />
            )}
            {index < steps.length - 1 && (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={!taskName}
                >
                  Continue
                </Button>
                <Button disabled={index === 0} onClick={handleBack}>
                  Back
                </Button>
              </Stack>
            )}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default MultipleVideoUpload;
