import { SettingDto } from "src/app/services/generatedApi";

export const categoryDefaultSettings: SettingDto = {
  neck: { threshold1: 0, threshold2: 0, threshold3: 10, threshold4: 20 },
  back: { threshold1: 0, threshold2: 0, threshold3: 20, threshold4: 60 },
  rightKnee: { threshold1: 0, threshold2: 0, threshold3: 30, threshold4: 60 },
  leftKnee: { threshold1: 0, threshold2: 0, threshold3: 30, threshold4: 60 },
  rightUpperArm: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 20,
    threshold4: 90,
  },
  leftUpperArm: {
    threshold1: 0,
    threshold2: 0,
    threshold3: 20,
    threshold4: 90,
  },
  rightLowerArm: {
    threshold1: 30,
    threshold2: 60,
    threshold3: 100,
    threshold4: 140,
  },
  leftLowerArm: {
    threshold1: 30,
    threshold2: 60,
    threshold3: 100,
    threshold4: 140,
  },
  leftWrist: { threshold1: 0, threshold2: 0, threshold3: 15, threshold4: 25 },
  rightWrist: { threshold1: 0, threshold2: 0, threshold3: 15, threshold4: 25 },
};
