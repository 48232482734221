import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC } from "react";
import { TagRo } from "src/app/services/generatedApi";

export const TagSelect: FC<{
  tag: TagRo;
  tagsArray: TagRo[];
  onChange: (event: SelectChangeEvent) => void;
}> = ({ tag, tagsArray, onChange }) => (
  <FormControl fullWidth size="small" sx={{ width: "100%", maxWidth: "352px" }}>
    <InputLabel id="tagLabelId">Tag</InputLabel>
    <Select
      labelId="tagLabelId"
      id="tag"
      value={tag.id}
      label="Tag"
      onChange={onChange}
    >
      {tagsArray.map(({ name, id }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
