import { FC, useCallback, useState } from "react";
import { TableCell, TableRow, TableBody } from "@mui/material";
import { tableOrderType } from "src/components/organisms/tasks/types";
import { UserRo } from "src/app/services/generatedApi";
import UpdateUserDialog from "../updateUser/UpdateUserDialog";
import { UserWithUsages } from "src/types/userTypes";
import { comparators, HeadIdType } from "./struct";
import { UserTableRow } from "./UserTableRow";
import UpdateUserLimitDialog from "../limitUser/UpdateUserLimitDialog";

type UsersTableBodyPropsType = {
  rows: UserWithUsages[];
  order: tableOrderType;
  orderBy: HeadIdType;
  page: number;
  rowsPerPage: number;
};

const UsersTableBody: FC<UsersTableBodyPropsType> = ({
  rows,
  order,
  orderBy,
  page,
  rowsPerPage,
}) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showLimitDialog, setShowLimitDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserRo | null>(null);

  const closeUserDialog = () => {
    setShowEditDialog(false);
    setSelectedUser(null);
  };
  const closeLimitDialog = () => {
    setShowLimitDialog(false);
    setSelectedUser(null);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const comparator = useCallback(
    (a: any, b: any) => {
      const compare = comparators[orderBy];
      const sign = order === "desc" ? 1 : -1;
      if (!compare) {
        return b[orderBy] < a[orderBy]
          ? -sign
          : b[orderBy] > a[orderBy]
            ? sign
            : 0;
      }
      return sign * compare(a[orderBy], b[orderBy]);
    },
    [order, orderBy],
  );

  return (
    <TableBody>
      {rows
        .map((row) => ({ ...row, name: row.firstName + " " + row.lastName }))
        .sort(comparator)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => (
          <UserTableRow
            row={row}
            key={index}
            index={index}
            setSelectedUser={setSelectedUser}
            setShowEditDialog={setShowEditDialog}
            setShowLimitDialog={setShowLimitDialog}
          />
        ))}
      {emptyRows > 0 && (
        <TableRow>
          <TableCell colSpan={6} />
        </TableRow>
      )}
      {selectedUser && (
        <UpdateUserDialog
          user={selectedUser}
          open={showEditDialog}
          handleClose={closeUserDialog}
        />
      )}
      {selectedUser && (
        <UpdateUserLimitDialog
          username={selectedUser.username}
          limit={selectedUser.limit}
          open={showLimitDialog}
          handleClose={closeLimitDialog}
        />
      )}
    </TableBody>
  );
};

export default UsersTableBody;
