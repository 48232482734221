import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const NoCategoriesMessage: React.FC = () => {
  return (
    <Stack justifyContent="center" alignItems="center" rowGap={4}>
      <Typography align="center" variant="h6">
        It looks like you have not created any angle thresholds yet. Please
        create one before adding a new video.
      </Typography>
      <RouterLink to="/angleThresholds" style={{ textDecoration: "none" }}>
        <Button variant="contained" color="primary">
          Create Angle Thresholds
        </Button>
      </RouterLink>
    </Stack>
  );
};

export default NoCategoriesMessage;
