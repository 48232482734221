import React from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "src/app/hooks";

interface NameEditorProps {
  localName: string;
  setLocalName: (name: string) => void;
  isEditingName: boolean;
  setIsEditingName: (isEditing: boolean) => void;
  initialName: string;
  isSystemCategory: boolean;
}

export const NameEditor: React.FC<NameEditorProps> = ({
  localName,
  setLocalName,
  isEditingName,
  setIsEditingName,
  initialName,
  isSystemCategory,
}) => {
  const { auth } = useAppSelector((state) => state);
  const isAdmin = auth?.isAdmin;

  const handleNameEdit = () => setIsEditingName(true);
  const handleNameSave = () => setIsEditingName(false);
  const handleNameCancel = () => {
    setIsEditingName(false);
    setLocalName(initialName);
  };

  const showEditButton = !isSystemCategory || isAdmin;

  return (
    <Box pb={1} display="flex" alignItems="center" justifyContent="center">
      {isEditingName ? (
        <Box display="flex" alignItems="center">
          <TextField
            value={localName}
            onChange={(e) => setLocalName(e.target.value)}
            variant="standard"
            sx={{ mr: 1 }}
          />
          <IconButton onClick={handleNameSave} size="small">
            <CheckIcon />
          </IconButton>
          <IconButton onClick={handleNameCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <Typography component="div" variant="h6" fontWeight="bold">
            {localName}
          </Typography>
          {showEditButton && (
            <IconButton onClick={handleNameEdit} size="small" sx={{ ml: 1 }}>
              <EditIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};
