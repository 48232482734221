import React from "react";
import Slider from "@mui/material/Slider";
import { alpha, styled } from "@mui/material/styles";
import { red, green, yellow, blue, grey } from "@mui/material/colors";
import { generateMarks } from "./utils";

const StyledSlider = styled(Slider)(({ theme }) => ({
  "&.MuiSlider-root": { height: "7px" },
  "& .MuiSlider-rail": { backgroundColor: red[200] },
  "& .MuiSlider-thumb": {
    width: "15px",
    height: "15px",
    border: `1px solid ${grey[500]}`,
    backgroundColor: grey[300],
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${alpha(blue[300], 0.16)}`,
    },
    "&.Mui-active": { boxShadow: `0px 0px 0px 14px ${alpha(blue[300], 0.16)}` },
  },
  "& .MuiSlider-track": { border: "none" },
  "& .MuiSlider-markLabel": { fontSize: "14px" },
}));

interface CustomSliderProps {
  point2: number;
  point3: number;
  thresholds: { [key: string]: number };
  onChange: (event: Event, newValue: number | number[]) => void;
  start: number;
  end: number;
  spaceBetween: number;
  disabled: boolean;
}

export const CustomSlider: React.FC<CustomSliderProps> = ({
  point2,
  point3,
  thresholds,
  onChange,
  start,
  end,
  spaceBetween,
  disabled,
}) => (
  <StyledSlider
    sx={{
      "& .MuiSlider-track": {
        background: `linear-gradient(90deg,
          ${yellow[600]} ${point2}%,
          ${green[500]} ${point2}%,
          ${green[500]} ${point3}%,
          ${yellow[600]} ${point3}%,
          ${yellow[600]} 100%)`,
      },
    }}
    track="inverted"
    getAriaLabel={() => "Temperature range"}
    value={Object.values(thresholds)}
    onChange={onChange}
    valueLabelDisplay="auto"
    min={start}
    max={end}
    getAriaValueText={(value) => `${value}°`}
    marks={generateMarks({ start, end, spaceBetween })}
    disabled={disabled}
  />
);
