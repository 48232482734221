import { Paper, Stack, Typography } from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";

import Template from "src/components/templates/Template";

export const AddVideoLimitRiched = () => {
  return (
    <Template>
      <Paper sx={{ p: 2 }}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <WarningIcon fontSize="large" color="error" />
          <Typography>
            {"You've reached your subscription limit."}
            <br />
            {"Please contact us at "}
            <a href="mailto:info@posechecker.com">info@posechecker.com</a>
            {" to renew your subscription."}
          </Typography>
        </Stack>
      </Paper>
    </Template>
  );
};
