import { Dispatch, FC, SetStateAction, useState } from "react";
import { Button, ButtonGroup } from "@mui/material";
import { useTasksControllerDeleteTasksMutation } from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useAppSelector } from "src/app/hooks";
import DeleteConfirmDialog, { DeleteType } from "./DeleteConfirmDialog";

export interface ToolbarActionsProps {
  selectedRows: string[];
  setSelectedRows: Dispatch<SetStateAction<string[]>>;
}

const ToolbarActions: FC<ToolbarActionsProps> = ({
  selectedRows,
  setSelectedRows,
}) => {
  const [deleteTasks, { isLoading: deleteTasksLoading }] =
    useTasksControllerDeleteTasksMutation();
  const navigate = useNavigate();
  const isAdmin = useAppSelector((state) => state.auth?.isAdmin);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteType, setDeleteType] = useState<DeleteType>("normal");

  const handleOpenConfirmDialog = (type: DeleteType) => {
    setDeleteType(type);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const deleteSelectedRows = () => {
    if (selectedRows.length < 1) return;
    deleteTasks({
      deleteTaskDto: {
        ids: selectedRows,
        permanent: deleteType === "permanent",
      },
    })
      .unwrap()
      .then(() => {
        setSelectedRows([]);
        toast.success(
          `Selected tasks successfully ${
            deleteType === "permanent" ? "permanently " : ""
          }deleted`,
        );
        handleCloseConfirmDialog();
      });
  };

  const compareSelectedRows = () => {
    if (selectedRows.length === 2) {
      navigate(`/compare/${selectedRows[0]}/${selectedRows[1]}`);
    }
  };

  return (
    <>
      {deleteTasksLoading && <Loading />}
      <ButtonGroup variant="contained">
        <Button
          color="info"
          onClick={compareSelectedRows}
          disabled={selectedRows.length !== 2}
        >
          Compare
        </Button>
        <Button color="error" onClick={() => handleOpenConfirmDialog("normal")}>
          Delete
        </Button>
        {isAdmin && (
          <Button
            color="error"
            onClick={() => handleOpenConfirmDialog("permanent")}
          >
            Permanently Delete
          </Button>
        )}
      </ButtonGroup>

      <DeleteConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={deleteSelectedRows}
        deleteType={deleteType}
      />
    </>
  );
};

export default ToolbarActions;
