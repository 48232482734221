import { FC } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Select,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { MinimalCategoryRo } from "src/app/services/generatedApi";

export const CategorySelect: FC<{
  category: MinimalCategoryRo;
  categoriesArray: (MinimalCategoryRo & { isSystemCategory: boolean })[];
  onChange: (event: SelectChangeEvent) => void;
}> = ({ category, categoriesArray, onChange }) => (
  <FormControl fullWidth size="small" sx={{ width: "100%", maxWidth: "352px" }}>
    <InputLabel id="angleThresholdsLabelId">Angle thresholds</InputLabel>
    <Select
      labelId="angleThresholdsLabelId"
      id="angleThresholds"
      value={category.id}
      label="Angle thresholds"
      onChange={onChange}
    >
      {categoriesArray.map(({ name, id, isSystemCategory }) => (
        <MenuItem key={id} value={id}>
          <Typography component="span">{name}</Typography>
          {isSystemCategory && (
            <Typography
              component="span"
              color="primary"
              fontSize={13}
              fontWeight="bold"
            >
              &nbsp;(SYSTEM ANGLE THRESHOLDS)
            </Typography>
          )}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
