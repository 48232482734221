import {
  Box,
  Paper,
  Stack,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { SettingDto } from "src/app/services/generatedApi";
import { SettingsNamesEnum } from "src/components/molecules/category/types";
import { BodyPartsChart } from "./charts/BodyPartsChart";
import { SelectBodyPart } from "./dialogs/SelectBodyPart";
import { TaskContext } from "src/context/TaskContext";

export const BodyPartsSection = () => {
  const { havePose } = useContext(TaskContext);

  const [selectedPart, setSelectedPart] = useState<keyof SettingDto>("neck");
  const [mode, setMode] = useState<"Default" | "Alpha" | "Beta" | "Gamma">(
    "Default",
  );

  useEffect(() => {
    setMode("Default");
  }, [selectedPart]);

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  return (
    <Paper
      elevation={isMd ? undefined : 0}
      sx={{
        width: "100%",
        pt: 2,
        px: 2,
        pb: 1,
        borderRadius: { xs: 0, md: 1 },
      }}
    >
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <SelectBodyPart setSelectedPart={setSelectedPart} />
        {havePose &&
          (selectedPart === "leftUpperArm" ||
            selectedPart === "rightUpperArm" ||
            selectedPart === "neck" ||
            selectedPart === "back") && (
            <ToggleButtonGroup
              value={mode}
              exclusive
              onChange={(_, val) => val && setMode(val)}
              size="small"
              color="primary"
            >
              <ToggleButton value="Default" title="Standard 3D Analysis">
                Default
              </ToggleButton>
              <ToggleButton value="Alpha" title="Coronal Plane Projection">
                Alpha
              </ToggleButton>
              <ToggleButton value="Beta" title="Sagittal Plane Projection">
                Beta
              </ToggleButton>
              <ToggleButton value="Gamma" title="Axial Plane Projection">
                Gamma
              </ToggleButton>
            </ToggleButtonGroup>
          )}
      </Stack>
      <Typography sx={{ m: "auto", textAlign: "center" }} variant="h6">
        {SettingsNamesEnum[selectedPart]} Chart
      </Typography>
      <Box sx={{ height: "300px", mt: 3 }}>
        <BodyPartsChart syncByVideo selectedPart={selectedPart} mode={mode} />
      </Box>
    </Paper>
  );
};
