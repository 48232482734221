import { FC } from "react";
import { useTasksControllerGetUserTasksWithPaginationQuery } from "src/app/services/generatedApi";
import BaseTasksComponent from "src/components/organisms/tasks/BaseTasksComponent";
import { tasksHeadCells } from "src/components/organisms/tasks/constants";

const Tasks: FC = () => {
  return (
    <BaseTasksComponent
      title="Videos"
      headCells={tasksHeadCells}
      fetchTasks={useTasksControllerGetUserTasksWithPaginationQuery}
    />
  );
};

export default Tasks;
