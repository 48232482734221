import { Angle3D, FullKeypoints, Keypoints } from "src/types/pose";
import {
  AngleJoints,
  HM36PoseLandmark,
  JointValue,
  joints,
} from "./poseConstants";
import { PersonPoses, PersonsRestructured } from "./poseProcessor";
import {
  addVectors,
  calculateAngleBetween3Points,
  calculateAngleBetweenVectorsInPlane,
  crossProduct,
  getAveragePoint,
  normalizeVector,
  subtractVectors,
} from "./geometryUtils";

const calculateJointAngle = (
  jointKey: AngleJoints,
  keypoints: Keypoints | FullKeypoints,
  coronalNorm?: number[],
  sagittalNorm?: number[],
  axialNorm?: number[],
): { angle: number; angle3D?: Angle3D } => {
  const joint = joints[jointKey];
  const mode = Array.isArray(keypoints) ? "3d" : "2d";
  const points =
    joint.points[mode]
      ?.map((index) => getAveragePoint(index, keypoints, mode))
      .filter((p) => p.isVisible)
      .map((p) => p.point.slice(0, 3)) || [];

  if (points.length < 3) {
    throw new Error("not enough points");
  }

  let angle: number | Angle3D = calculateAngleBetween3Points(points, mode);

  if (joint.reverse) {
    angle = 180 - angle;
  }

  if (mode === "3d" && joint.have3DAngle) {
    const [p1, p2, p3] = points;
    const v1 = subtractVectors(p1.slice(0, 3), p2.slice(0, 3), "3d");
    const v2 = subtractVectors(p3.slice(0, 3), p2.slice(0, 3), "3d");

    let Alpha = calculateAngleBetweenVectorsInPlane(v1, v2, coronalNorm!);
    let Beta = calculateAngleBetweenVectorsInPlane(v1, v2, sagittalNorm!);
    let Gamma = calculateAngleBetweenVectorsInPlane(
      v2,
      coronalNorm!,
      axialNorm!,
    );

    if (jointKey === "neck") {
      Alpha = Alpha - Math.sign(Alpha) * 180;
      Beta = Beta - Math.sign(Beta) * 180 - 10;
      // Gamma = Gamma - Math.sign(Gamma) * 180;
      angle = (angle - 20) * 0.8;
    }

    if (jointKey === "back") {
      Alpha = -Alpha + Math.sign(Alpha) * 180;
      Beta = Beta - Math.sign(Beta) * 180 + 5;
      Gamma = Gamma - Math.sign(Gamma) * 180;
    }

    Alpha = Math.round(Alpha * 10) / 10;
    Beta = Math.round(Beta * 10) / 10;
    Gamma = Math.round(Gamma * 10) / 10;
    return {
      angle,
      angle3D: { Alpha, Beta, Gamma },
    };
  }

  return {
    angle,
  };
};

const calculatePlaneNorm = (
  keypoints: Keypoints,
  startIndex1: HM36PoseLandmark,
  startIndex2: HM36PoseLandmark,
  endIndex1: HM36PoseLandmark,
  endIndex2: HM36PoseLandmark,
): number[] => {
  const startPoint = addVectors(
    keypoints[startIndex1].slice(0, 3),
    keypoints[startIndex2].slice(0, 3),
  );
  const endPoint = addVectors(
    keypoints[endIndex1].slice(0, 3),
    keypoints[endIndex2].slice(0, 3),
  );
  return normalizeVector(subtractVectors(startPoint, endPoint, "3d"));
};

const calculatePersonAngles = (personPoses: PersonPoses): void => {
  personPoses.forEach(({ personPose }, index: number) => {
    const sagittalNorm = calculatePlaneNorm(
      personPose.keypoints3d,
      HM36PoseLandmark.LEFT_HIP,
      HM36PoseLandmark.LEFT_SHOULDER,
      HM36PoseLandmark.RIGHT_HIP,
      HM36PoseLandmark.RIGHT_SHOULDER,
    );
    const axialNorm = calculatePlaneNorm(
      personPose.keypoints3d,
      HM36PoseLandmark.LEFT_HIP,
      HM36PoseLandmark.RIGHT_HIP,
      HM36PoseLandmark.LEFT_SHOULDER,
      HM36PoseLandmark.RIGHT_SHOULDER,
    );
    const coronalNorm = normalizeVector(crossProduct(sagittalNorm, axialNorm));

    (Object.entries(joints) as [AngleJoints, JointValue][]).forEach(
      ([jointKey, jointValue]) => {
        try {
          const factors = jointValue.factors || [2, 8];
          const angleIn2D =
            factors[0] > 0
              ? calculateJointAngle(jointKey, personPose.keypoints).angle
              : 0;
          const { angle: angleIn3D, angle3D } =
            factors[1] > 0
              ? calculateJointAngle(
                  jointKey,
                  personPose.keypoints3d,
                  coronalNorm,
                  sagittalNorm,
                  axialNorm,
                )
              : { angle: 0, angle3D: undefined };
          const angle =
            Math.round(factors[0] * angleIn2D + factors[1] * angleIn3D) / 10;
          personPose.angles[jointKey] = angle;
          if (angle3D) {
            personPose.angles3D[jointKey] = angle3D;
          }
        } catch {}
      },
    );
  });
};

export const calculateAngles = (persons: PersonsRestructured): void => {
  persons.forEach(calculatePersonAngles);
};
