import reba1 from "src/assets/images/reba/reba1.png";
import reba2 from "src/assets/images/reba/reba2.png";
import reba3 from "src/assets/images/reba/reba3.png";
import reba4 from "src/assets/images/reba/reba4.png";
import reba5 from "src/assets/images/reba/reba5.png";
import reba6 from "src/assets/images/reba/reba6.png";
import reba7 from "src/assets/images/reba/reba7.png";
import reba8 from "src/assets/images/reba/reba8.png";
import reba9 from "src/assets/images/reba/reba9.png";
import reba10 from "src/assets/images/reba/reba10.png";
import reba11 from "src/assets/images/reba/reba11.png";
import reba12 from "src/assets/images/reba/reba12.png";
import reba13 from "src/assets/images/reba/reba13.png";
import reba14 from "src/assets/images/reba/reba14.png";
import reba15 from "src/assets/images/reba/reba15.png";
import reba16 from "src/assets/images/reba/reba16.png";
import reba17 from "src/assets/images/reba/reba17.png";
import reba18 from "src/assets/images/reba/reba18.png";
import reba19 from "src/assets/images/reba/reba19.png";
import reba20 from "src/assets/images/reba/reba20.png";
import reba21 from "src/assets/images/reba/reba21.png";

import { QuestionType } from "../reba-rula/types";
import {
  RebaCouplingMode,
  RebaForceMode,
  RebaItems,
  RebaLeg,
  RebaLowerArm,
  RebaNeck,
  RebaQuestions,
  RebaTrunk,
  RebaUpperArm,
  RebaWrist,
} from "./types";
import { Angle3D } from "src/types/pose";
import { deepCloneRebaQuestions } from "../utils";

const rebaQuestions: RebaQuestions = {
  [RebaItems.NECK_ANGLE]: {
    type: QuestionType.SELECT,
    selectedIndex: 0,
    question: "Neck Position",
    items: [
      {
        img: reba1,
        text: "neck 0 to 20",
        id: RebaNeck.ANGLE_0_20,
        value: 1,
      },
      {
        img: reba2,
        text: "neck 20 plus",
        id: RebaNeck.ANGLE_20_PLUS,
        value: 2,
      },
      {
        img: reba3,
        text: "neck 0 minus",
        id: RebaNeck.ANGLE_0_MINUS,
        value: 2,
      },
    ],
    update(angle: number) {
      this.selectedIndex = angle < 20 ? (angle > 0 ? 0 : 2) : 1;
    },
  },
  [RebaItems.NECK_ADJUST]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Neck is twisted: +1",
        selected: false,
        value: 1,
      },
      {
        text: "Neck is side bending: +1",
        selected: false,
        value: 1,
      },
    ],
  },
  [RebaItems.TRUNK_ANGLE]: {
    type: QuestionType.SELECT,
    selectedIndex: 0,
    question: "Trunk Position",
    items: [
      {
        img: reba9,
        text: "trunk 0",
        id: RebaTrunk.ANGLE_0_10,
        value: 1,
      },
      {
        img: reba10,
        text: "trunk 0 minus",
        id: RebaTrunk.ANGLE_0_MINUS,
        value: 2,
      },
      {
        img: reba11,
        text: "trunk 0 to 20",
        id: RebaTrunk.ANGLE_10_20,
        value: 2,
      },
      {
        img: reba12,
        text: "trunk 20 to 60",
        id: RebaTrunk.ANGLE_20_60,
        value: 3,
      },
      {
        img: reba13,
        text: "trunk 60 plus",
        id: RebaTrunk.ANGLE_60_PLUS,
        value: 4,
      },
    ],
    update(angle: number, selectedAngle3D?: Angle3D) {
      const betaAngle = selectedAngle3D?.Beta || angle;
      this.selectedIndex =
        betaAngle < -8
          ? 1
          : betaAngle < 10
            ? 0
            : betaAngle < 20
              ? 2
              : betaAngle < 60
                ? 3
                : 4;
    },
  },
  [RebaItems.TRUNK_ADJUST]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Trunk is twisted: +1",
        selected: false,
        value: 1,
      },
      {
        text: "Trunk is side bending: +1",
        selected: false,
        value: 1,
      },
    ],
    update(selectedAngle3D?: Angle3D) {
      if (!selectedAngle3D?.Beta || !selectedAngle3D?.Alpha) {
        return;
      }
      if (
        Math.abs(selectedAngle3D.Alpha) - Math.abs(selectedAngle3D.Beta) >
        10
      ) {
        this.items[0].selected = false;
        this.items[1].selected = true;
      } else {
        this.items[0].selected = false;
        this.items[1].selected = false;
      }
    },
  },
  [RebaItems.LEG_DOWN]: {
    type: QuestionType.SELECT,
    question: "Legs Position",
    selectedIndex: 0,
    items: [
      {
        img: reba16,
        text: "Both legs down",
        id: false,
        value: 1,
      },
      {
        img: reba17,
        text: "One leg down",
        id: true,
        value: 2,
      },
    ],
    update(leftKneeAngle?: number, rightKneeAngle?: number) {
      this.selectedIndex =
        !leftKneeAngle ||
        !rightKneeAngle ||
        Math.abs(leftKneeAngle - rightKneeAngle) > 15
          ? 1
          : 0;
    },
  },
  [RebaItems.LEG_ANGLE]: {
    type: QuestionType.SELECT_OPTIONAL,
    selectedIndex: 0,
    items: [
      {
        img: reba18,
        text: "lges 30 to 60",
        id: RebaLeg.ANGLE_30_60,
        value: 1,
      },
      {
        img: reba19,
        text: "legs 60 plus",
        id: RebaLeg.ANGLE_60_PLUS,
        value: 2,
      },
    ],
    update(angle: number) {
      this.selectedIndex = angle < 30 ? undefined : angle < 60 ? 0 : 1;
    },
  },
  [RebaItems.FORCE_MODE]: {
    type: QuestionType.SELECT,
    question: "Force/Load",
    selectedIndex: 0,
    items: [
      {
        text: "load < 11 lbs: +0",
        id: RebaForceMode.LOAD_0_11_LBS,
        value: 0,
      },
      {
        text: "load 11 to 22 lbs: +1",
        id: RebaForceMode.LOAD_11_22_LBS,
        value: 1,
      },
      {
        text: "load > 22 lbs: +2",
        id: RebaForceMode.LOAD_22_PLUS_LBS,
        value: 2,
      },
    ],
    update(load: number) {
      this.selectedIndex = load < 11 ? 0 : load < 22 ? 1 : 2;
    },
  },
  [RebaItems.SHOCK]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Shock or rapid build up of force: +1",
        selected: false,
        value: 1,
      },
    ],
  },
  [RebaItems.UPPER_ARM_ANGLE]: {
    type: QuestionType.SELECT,
    question: "Upper Arm Position",
    selectedIndex: 0,
    items: [
      {
        img: reba4,
        text: "Upper arm -20 to 20",
        id: RebaUpperArm.ANGLE__20_20,
        value: 1,
      },
      {
        img: reba5,
        text: "Upper arm -20 minus",
        id: RebaUpperArm.ANGLE__20_MINUS,
        value: 2,
      },
      {
        img: reba6,
        text: "Upper arm 20 to 45",
        id: RebaUpperArm.ANGLE_20_45,
        value: 2,
      },
      {
        img: reba7,
        text: "Upper arm 45 to 90",
        id: RebaUpperArm.ANGLE_45_90,
        value: 3,
      },
      {
        img: reba8,
        text: "Upper arm 90 plus",
        id: RebaUpperArm.ANGLE_90_PLUS,
        value: 4,
      },
    ],
    update(angle: number) {
      this.selectedIndex =
        angle < -20 ? 1 : angle < 20 ? 0 : angle < 45 ? 2 : angle < 90 ? 3 : 4;
    },
  },
  [RebaItems.UPPER_ARM_ADJUST]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Shoulder is raised: +1",
        selected: false,
        value: 1,
      },
      {
        text: "Upper arm is abducted: +1",
        selected: false,
        value: 1,
      },
      {
        text: "Arm is supported or person is leaning: -1",
        selected: false,
        value: -1,
      },
    ],
  },
  [RebaItems.LOWER_ARM_ANGLE]: {
    type: QuestionType.SELECT,
    question: "Lower Arm Position",
    selectedIndex: 0,
    items: [
      {
        img: reba14,
        text: "Lower arm 60 to 100",
        id: RebaLowerArm.ANGLE_60_100,
        value: 1,
      },
      {
        img: reba15,
        text: "Lower arm 0 to 60 or 100 plus",
        id: RebaLowerArm.ANGLE_0_60_OR_100_PLUS,
        value: 2,
      },
    ],
    update(angle: number) {
      this.selectedIndex = angle < 100 && angle > 60 ? 0 : 1;
    },
  },
  [RebaItems.WRIST_ANGLE]: {
    type: QuestionType.SELECT,
    question: "Wrist Position",
    selectedIndex: 0,
    items: [
      {
        img: reba20,
        text: "Wrist -15 to 15",
        id: RebaWrist.ANGLE__15_15,
        value: 1,
      },
      {
        img: reba21,
        text: "Wrist -15 minus or 15 plus",
        id: RebaWrist.ANGLE_GT_15,
        value: 2,
      },
    ],
    update(angle: number) {
      this.selectedIndex = angle < 15 && angle > -15 ? 0 : 1;
    },
  },
  [RebaItems.BENT_WRIST]: {
    type: QuestionType.CHECK,
    items: [
      {
        text: "Wrist is bent from midline or twisted: +1",
        selected: false,
        value: 1,
      },
    ],
  },
  [RebaItems.COUPLING_MODE]: {
    type: QuestionType.SELECT,
    question: "Coupling",
    selectedIndex: 0,
    items: [
      {
        text: "Well handle and mid range power grip: +0",
        id: RebaCouplingMode.GOOD,
        value: 0,
      },
      {
        text: "Acceptable but not ideal hand hold or coupling acceptable with another body part: +1",
        id: RebaCouplingMode.FAIR,
        value: 1,
      },
      {
        text: "Hand hold not acceptable but possible: +2",
        id: RebaCouplingMode.POOR,
        value: 2,
      },
      {
        text: "No handles, awkward, unsafe with any body part: +3",
        id: RebaCouplingMode.UNACCEPTABLE,
        value: 3,
      },
    ],
    update: () => {},
  },
  [RebaItems.ACTIVITY]: {
    type: QuestionType.CHECK,
    question: "Activity",
    items: [
      {
        text: "1 or more body parts are held for longer than 1 minute (static): +1",
        selected: false,
        value: 1,
      },
      {
        text: "Repeated small range actions (more than 4x per minute): +1",
        selected: false,
        value: 1,
      },
      {
        text: "Action causes rapid large range changes in postures or unstable base: +1",
        selected: false,
        value: 1,
      },
    ],
  },
};

export const getRebaQuestions = (): RebaQuestions =>
  deepCloneRebaQuestions(rebaQuestions);
