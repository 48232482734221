import React, { useState } from "react";
import {
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { toast } from "react-toastify";
import Loading from "src/components/atoms/Loading";
import CategorySettings from "src/components/organisms/category/CategorySettings";
import {
  SettingDto,
  useCategoryControllerCreateCategoryMutation,
  useCategoryControllerCreateSystemCategoryMutation,
} from "src/app/services/generatedApi";
import { useAppSelector } from "src/app/hooks";
import { categoryDefaultSettings } from "src/components/molecules/category/constants";

type AddAngleThresholdsProps = {
  closeModal: () => void;
};

const AddAngleThresholds: React.FC<AddAngleThresholdsProps> = ({
  closeModal,
}) => {
  const [name, setName] = useState("");
  const [isSystemCategory, setIsSystemCategory] = useState(false);
  const [settings, setSettings] = useState<SettingDto>(categoryDefaultSettings);

  const [createCategory, { isLoading: isCreatingCategory }] =
    useCategoryControllerCreateCategoryMutation();
  const [createSystemCategory, { isLoading: isCreatingSystemCategory }] =
    useCategoryControllerCreateSystemCategoryMutation();

  const { auth } = useAppSelector((state) => state);

  const handleCreateCategory = async () => {
    if (!name || !settings) return;

    try {
      if (auth?.isAdmin) {
        await createSystemCategory({
          createSystemCategoryDto: {
            name,
            setting: settings,
            isSystemCategory,
          },
        }).unwrap();
        toast.success("Your system angle thresholds successfully created");
      } else {
        await createCategory({
          createCategoryDto: { name, setting: settings },
        }).unwrap();
        toast.success("Your angle thresholds successfully created");
      }
      closeModal();
    } catch (error) {
      toast.error("Failed to create angle thresholds");
    }
  };

  const isLoading = isCreatingCategory || isCreatingSystemCategory;

  return (
    <>
      {isLoading && <Loading />}
      <DialogTitle sx={{ textAlign: "center" }}>
        Add new angle thresholds
      </DialogTitle>
      <DialogContent sx={{ p: { xs: "auto", md: 1 } }}>
        <Grid
          container
          direction="row"
          sx={{
            mb: 4,
            mt: 2,
            px: { xs: 0, md: 3 },
          }}
          columnGap={{ xs: 0, md: 7 }}
          rowGap={{ xs: 2, md: 0 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md>
            <TextField
              fullWidth
              label="Angle thresholds name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md>
            {auth?.isAdmin && (
              <FormControlLabel
                sx={{ ml: "auto" }}
                control={
                  <Switch
                    checked={isSystemCategory}
                    onChange={() => setIsSystemCategory((prev) => !prev)}
                  />
                }
                label="Set as system angle threshold"
              />
            )}
          </Grid>
        </Grid>
        <CategorySettings value={settings} onChange={setSettings} />
      </DialogContent>
      <DialogActions sx={{ pr: { xs: 1, sm: 2, md: 4 }, pb: 2 }}>
        <Button sx={{ width: "100%", maxWidth: "130px" }} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          onClick={handleCreateCategory}
          disabled={!name}
          sx={{ width: "100%", maxWidth: "130px" }}
          variant="contained"
          type="submit"
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default AddAngleThresholds;
