import { FC, useEffect, useState, useRef } from "react";
import {
  Stack,
  Switch,
  TextField,
  Grid,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Chip,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

import Dropzone from "src/components/organisms/dropzone/Dropzone";
import AutocompleteWithAdd, {
  nameIdType,
} from "src/components/atoms/AutocompleteWithAdd";
import { UploadProcessDialog } from "src/components/organisms/addVideo/uploadProcess/UploadProcessDialog";

import { useCustomMediaControllerUploadVideoMutation } from "src/app/services/api";
import { CategoriesRo, TagsRo } from "src/app/services/generatedApi";

export const tagModifier = (tag: string | nameIdType | null) => {
  if (!tag) return "";
  return typeof tag === "string"
    ? tag
    : tag.name.replaceAll('"', "").replace("Add ", "");
};

const SingleVideoUpload: FC<{
  allCategories?: CategoriesRo;
  allTags?: TagsRo;
}> = ({ allCategories, allTags }) => {
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState<any>(null);
  const [category, setCategory] = useState("");
  const [tag, setTag] = useState<string | nameIdType | null>(null);
  const [description, setDescription] = useState("");
  const [blurFace, setBlurFace] = useState(false);
  const [blurBack, setBlurBack] = useState(false);
  const [multiTarget, setMultiTarget] = useState(false);

  useEffect(() => {
    if (allCategories) {
      const defaultCategory = allCategories.categories.find(
        (cat) => cat.name === "Default" && cat.isSystemCategory,
      );
      if (defaultCategory) {
        setCategory(defaultCategory.id);
      }
    }
  }, [allCategories]);

  const [createTask] = useCustomMediaControllerUploadVideoMutation();
  const [uploadProcessEvent, setUploadProcessEvent] = useState<
    { loaded: number; total?: number } | undefined
  >();
  const abortController = useRef<AbortController | undefined>();
  const navigate = useNavigate();
  const someFieldIsEmpty = !fileName || !file || !category;

  const submitHandler = () => {
    if (someFieldIsEmpty) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("categoryId", category);
    formData.append("name", fileName);
    formData.append("description", description);
    formData.append("tagName", tagModifier(tag) || "");
    formData.append("blurFace", blurFace as any);
    formData.append("blurBack", blurBack as any);
    formData.append("multiTarget", multiTarget as any);

    abortController.current = new AbortController();

    createTask({
      body: formData as any,
      abortController: abortController.current,
      onUploadProgress: setUploadProcessEvent,
    })
      .unwrap()
      .then(() => {
        setFileName("");
        setFile(null);
        setCategory("");
        setTag(null);
        setDescription("");
        toast.success("Task successfully added");
        navigate("/results");
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="File name"
                fullWidth
                value={fileName}
                onChange={({ target: { value } }) => setFileName(value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel id="angleThresholdsLabelId">
                  Angle Thresholds
                </InputLabel>
                <Select
                  required
                  labelId="angleThresholdsLabelId"
                  id="angleThresholds"
                  value={category}
                  label="AngleThresholds"
                  onChange={({ target: { value } }) => setCategory(value)}
                >
                  {allCategories?.categories &&
                    [...allCategories.categories]
                      .reverse()
                      .map(({ name, id, isSystemCategory }, index) => (
                        <MenuItem key={index} value={id}>
                          <Typography component="span">{name}</Typography>
                          {isSystemCategory && (
                            <Typography
                              component="span"
                              color="primary"
                              fontSize={13}
                              fontWeight="bold"
                            >
                              &nbsp;(SYSTEM ANGLE THRESHOLDS)
                            </Typography>
                          )}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutocompleteWithAdd
            onChange={setTag}
            options={(allTags?.tags as any) || []}
            label="Tag"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={blurFace}
                  onChange={() => setBlurFace(!blurFace)}
                />
              }
              label="Blur Faces"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={blurBack}
                  onChange={() => setBlurBack(!blurBack)}
                />
              }
              label="Blur Background"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={multiTarget}
                  onChange={() => setMultiTarget(!multiTarget)}
                />
              }
              label={
                <Stack direction="row" spacing={1}>
                  <Typography>Multi-Subject Analysis</Typography>
                  <Tooltip title="The multi-subject feature, still in development, is designed to analyze and conduct assessments on several individuals within a single uploaded video. This innovative functionality enables users to seamlessly toggle between the analysis results of each person included in the video.">
                    <Chip label="Beta" size="small" color="warning" />
                  </Tooltip>
                </Stack>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={6}
            label="Description"
            fullWidth
            value={description}
            onChange={({ target: { value } }) => setDescription(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Dropzone onChange={setFile} />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={someFieldIsEmpty}
            onClick={submitHandler}
            sx={{ width: "100%", maxWidth: "330px" }}
            variant="contained"
          >
            <Typography fontWeight="bold">Submit</Typography>
          </Button>
        </Grid>
      </Grid>

      <UploadProcessDialog
        handleClose={() => setUploadProcessEvent(undefined)}
        uploadProcessEvent={uploadProcessEvent as any}
        cancel={() => {
          abortController.current?.abort();
          setUploadProcessEvent(undefined);
        }}
      />
    </>
  );
};

export default SingleVideoUpload;
