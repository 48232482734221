import React, { useCallback } from "react";
import { Grid, Typography } from "@mui/material";
import { SettingDto, ThresholdDto } from "src/app/services/generatedApi";
import { CustomSlider } from "src/components/molecules/category/CustomSlider";
import { SettingsNamesEnum } from "src/components/molecules/category/types";
import { angleToPercentage } from "src/components/molecules/category/utils";

interface CategorySettingsProps {
  value: SettingDto;
  onChange?: (newSetting: SettingDto) => void;
}

const CategorySettings: React.FC<CategorySettingsProps> = ({
  value,
  onChange,
}) => {
  const handleChange = (newValue: number | number[], itemName: string) => {
    if (!Array.isArray(newValue) || !onChange) return;
    const result: ThresholdDto = {
      threshold1: newValue[0],
      threshold2: newValue[1],
      threshold3: newValue[2],
      threshold4: newValue[3],
    };
    onChange({ ...value, [itemName]: result });
  };

  const minMax = useCallback(
    (itemName: keyof SettingDto) => {
      const { threshold1, threshold2, threshold3, threshold4 } =
        value[itemName];
      return {
        min: Math.min(threshold1, threshold2, threshold3, threshold4),
        max: Math.max(threshold1, threshold2, threshold3, threshold4),
      };
    },
    [value],
  );

  return (
    <Grid container>
      {Object.entries(value).map(([item, thresholds], index) => {
        const isNeck = item === "neck";
        const start = isNeck ? -90 : 0;
        const end = isNeck ? 90 : 180;
        const spaceBetween = 30;

        const point2 = angleToPercentage({
          number: thresholds.threshold2,
          itemName: item as keyof SettingDto,
          minMax,
        });
        const point3 = angleToPercentage({
          number: thresholds.threshold3,
          itemName: item as keyof SettingDto,
          minMax,
        });

        return (
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ py: 0.5, px: { xs: 0, sm: 2, md: 3, lg: 4 } }}
            key={index}
          >
            <Typography fontWeight={500} fontSize={16}>
              {SettingsNamesEnum[item as keyof typeof SettingsNamesEnum]}
            </Typography>
            <CustomSlider
              point2={point2}
              point3={point3}
              thresholds={thresholds}
              onChange={(_, newValue) => handleChange(newValue, item)}
              start={start}
              end={end}
              spaceBetween={spaceBetween}
              disabled={!onChange}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CategorySettings;
