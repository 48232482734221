import { HeadCell } from "./TableHead";

export const allTasksHeadCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "creator",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "riskScore",
    numeric: true,
    disablePadding: false,
    label: "Risk Score",
  },
  {
    id: "isDeleted",
    numeric: false,
    disablePadding: false,
    label: "Deleted",
  },
  {
    id: "tag",
    numeric: false,
    disablePadding: false,
    label: "Tag",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Date created",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

export const tasksHeadCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "riskScore",
    numeric: true,
    disablePadding: false,
    label: "Risk Score",
  },
  {
    id: "tag",
    numeric: false,
    disablePadding: false,
    label: "Tag",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
  },
];
