import React, { FC, Dispatch, SetStateAction } from "react";
import { Grid } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  MinimalCategoryRo,
  TagRo,
  useCategoryControllerGetAllCategoriesQuery,
  useTasksControllerGetUserTagsForCurrentUserQuery,
  useUsersControllerGetAllUsersUsageQuery,
} from "src/app/services/generatedApi";
import Loading from "src/components/atoms/Loading";
import { TagSelect } from "./TagSelect";
import { CategorySelect } from "./CategorySelect";
import { CreatorSelect } from "./CreatorSelect";
import { SearchField } from "./SearchField";

type ToolbarFiltersProps = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  category?: MinimalCategoryRo;
  setCategory?: Dispatch<SetStateAction<MinimalCategoryRo>>;
  tag?: TagRo;
  setTag?: Dispatch<SetStateAction<TagRo>>;
  creator?: string;
  setCreator?: Dispatch<SetStateAction<string>>;
  isAllTasks?: boolean;
};

const ToolbarFilters: FC<ToolbarFiltersProps> = ({
  search,
  setSearch,
  category,
  setCategory,
  tag,
  setTag,
  creator,
  setCreator,
  isAllTasks = false,
}) => {
  const { data: allCategories, isLoading: isCategoriesLoading } =
    useCategoryControllerGetAllCategoriesQuery();
  const { data: allTags, isLoading: isTagsLoading } =
    useTasksControllerGetUserTagsForCurrentUserQuery();
  const { data: allUsersUsage, isLoading: isUsersUsageLoading } =
    useUsersControllerGetAllUsersUsageQuery(null as any, {
      skip: !isAllTasks,
    });

  const categoriesArray = React.useMemo(() => {
    const defaultCategory = { id: "all", name: "All", isSystemCategory: false };
    return allCategories
      ? [...allCategories.categories, defaultCategory]
      : [defaultCategory];
  }, [allCategories]);

  const tagsArray = React.useMemo(() => {
    const defaultTag = { id: "all", name: "All" };
    return allTags ? [...allTags.tags, defaultTag] : [defaultTag];
  }, [allTags]);

  const handleCategoryChange = (event: SelectChangeEvent) => {
    if (!setCategory) return;
    const value = event.target.value;
    const selectedCategory = categoriesArray.find(({ id }) => id === value) || {
      id: "all",
      name: "All",
    };
    setCategory(selectedCategory);
  };

  const handleTagChange = (event: SelectChangeEvent) => {
    if (!setTag) return;
    const value = event.target.value;
    const selectedTag = tagsArray.find(({ id }) => id === value) || {
      id: "all",
      name: "All",
    };
    setTag(selectedTag);
  };

  const handleCreatorChange = (event: SelectChangeEvent) => {
    if (setCreator) {
      setCreator(event.target.value);
    }
  };

  if (
    isCategoriesLoading ||
    isTagsLoading ||
    (isAllTasks && isUsersUsageLoading)
  ) {
    return <Loading />;
  }

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      sx={{ minWidth: category && tag ? "80%" : undefined }}
    >
      <Grid item xs={12} md={category && tag ? 4 : undefined}>
        <SearchField search={search} setSearch={setSearch} />
      </Grid>
      {category && (
        <Grid item xs={12} md>
          <CategorySelect
            category={category}
            categoriesArray={categoriesArray}
            onChange={handleCategoryChange}
          />
        </Grid>
      )}
      {tag && (
        <Grid item xs={12} md>
          <TagSelect
            tag={tag}
            tagsArray={tagsArray}
            onChange={handleTagChange}
          />
        </Grid>
      )}
      {isAllTasks && creator && setCreator && allUsersUsage && (
        <Grid item xs={12} md>
          <CreatorSelect
            creator={creator}
            usersUsageAndLimit={allUsersUsage.usersUsageAndLimit}
            onChange={handleCreatorChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ToolbarFilters;
