import { useState, useEffect } from "react";
import { SettingDto } from "src/app/services/generatedApi";

export const useLocalState = (
  initialSetting: SettingDto | null,
  initialName: string,
) => {
  const [localSetting, setLocalSetting] = useState<SettingDto | null>(null);
  const [localName, setLocalName] = useState(initialName);
  const [isEditingName, setIsEditingName] = useState(false);

  useEffect(() => {
    setLocalSetting(initialSetting);
    setLocalName(initialName);
  }, [initialSetting, initialName]);

  const handleSettingChange = (newSetting: SettingDto) => {
    setLocalSetting(newSetting);
  };

  return {
    localSetting,
    localName,
    isEditingName,
    setIsEditingName,
    setLocalName,
    handleSettingChange,
  };
};
