import { FC } from "react";
import { Add, ArrowBack, Message, QuestionMark } from "@mui/icons-material";
import { Fab, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { AssessmentContext } from "src/context/AssessmentContext";
import { NotesDialog } from "./note/NotesDialog";
import { TaskRo } from "src/app/services/generatedApi";
import { TaskContext } from "src/context/TaskContext";

export const FloatingAssessmentButtons: FC<{ task: TaskRo }> = ({ task }) => {
  const {
    id,
    assessmentPageIndex,
    setAssessmentPageIndex,
    reset,
    setHelpMethod,
    setOpenSelectAssessmentMethodDialog,
    selectedMethod,
  } = useContext(AssessmentContext);
  const { openNotes, setOpenNotes } = useContext(TaskContext);

  const handleFabClick = () => {
    if (id) {
      setAssessmentPageIndex(0);
    } else if (assessmentPageIndex > 0) {
      setAssessmentPageIndex(assessmentPageIndex - 1);
    } else {
      setOpenSelectAssessmentMethodDialog(true);
    }

    if (assessmentPageIndex > 1) {
      reset({ method: selectedMethod! });
    } else {
      reset();
    }
  };

  const handleHelpClick = () => {
    setHelpMethod(selectedMethod);
  };

  const toggleNotes = () => {
    setOpenNotes(!openNotes);
  };

  return (
    <>
      <Stack
        sx={{
          zIndex: 100,
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Fab
          variant="extended"
          onClick={toggleNotes}
          style={{ color: "white", backgroundColor: "#FFC107" }}
        >
          <Message sx={{ mr: 1 }} />
          <Typography fontWeight={500}>Notes</Typography>
        </Fab>
        <Fab
          variant="extended"
          color={assessmentPageIndex ? "default" : "primary"}
          onClick={handleFabClick}
          sx={{
            color: assessmentPageIndex ? "black" : "white",
          }}
        >
          {assessmentPageIndex ? (
            <>
              <ArrowBack sx={{ mr: 1 }} />
              <Typography fontWeight={500}>Back</Typography>
            </>
          ) : (
            <>
              <Add sx={{ mr: 1 }} />
              <Typography fontWeight={500}>Assessments</Typography>
            </>
          )}
        </Fab>
        {assessmentPageIndex > 0 && (
          <Fab
            variant="circular"
            color="default"
            onClick={handleHelpClick}
            sx={{
              color: "black",
            }}
          >
            <QuestionMark />
          </Fab>
        )}
      </Stack>
      <NotesDialog open={openNotes} onClose={toggleNotes} task={task} />
    </>
  );
};
